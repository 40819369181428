import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    path: '/connect',
    component: () => import('../views/Connect/Index')
  },
  {
    path: '/',
    component: () => import('../views/Index/Index')
  },
  {
    path: '/coming',
    component: () => import('../views/Index/Coming')
  },
  {
    path: '/card',
    component: () => import('../views/Card/Index')
  },
  {
    path: '/card/detail',
    component: () => import('../views/Card/Detail')
  },
  {
    path: '/product',
    component: () => import('../views/Product/Index')
  },
  {
    path: '/success',
    component: () => import('../views/Product/Success')
  },
  {
    path: '/myOrder',
    component: () => import('../views/MyOrder/Index')
  },
  {
    path: '/myOrder/detail',
    component: () => import('../views/MyOrder/Detail')
  },
  {
    path: '/mine',
    component: () => import('../views/Mine/Index')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router;
