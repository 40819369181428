import { createApp } from 'vue'
import App from './App.vue'

import router from "./router";
import { Button, Modal, Radio, Upload, Select, Carousel, Spin, Dropdown, Pagination, Tooltip } from 'ant-design-vue';
import './assets/css/base.less'
import i18n from "./i18n";
import Menu from './components/Menu.vue'
import Header from  './components/Header.vue'

const app = createApp(App);
app.use(router);
app.use(i18n);
app.use(Button).use(Modal).use(Radio).use(Upload).use(Select).use(Carousel).use(Spin)
  .use(Dropdown).use(Pagination).use(Tooltip);
app.component('Menu', Menu)
app.component('Header', Header)
app.mount('#app')
