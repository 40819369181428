// 保留小数位
export const getFloat = (val, num = 2) => {
  if (!Number(num)) {
    num = 2
  }
  switch(num){
    case 4:
      val = Math.floor(parseFloat(val)* 10000)/10000;
      break;
    default:
      val = Math.floor(parseFloat(val)* 100)/100;
      break;
  }
  if (!val) {
    val = 0
    val = parseFloat(val).toFixed(num);
  }else{
    val = parseFloat(val).toFixed(num);
  }
  return val;
}

// 隐藏信息
export const getEllipsis = (val, symbol, m, n) => {
  if(m === 'front') {
    const end = val.substr(-n)
    return symbol + end
  } else {
    const start = val.substr(0, m)
    const end = n ? val.substr(-n) : ''
    return start + symbol + end
  }
}

// 时间格式化
export const formatTime = val => {
  const month = val.substr(5, 2);
  const day = val.substr(8, 2);
  const year = val.substr(2, 2);
  const time = val.substr(10, 8);
  return month + '/' + day + '/' + year + time;
}

// 查询价格
export const getPrice = (type) => {
  type = type.toLowerCase()
  if(type !== 'land' && type !== 'ama') {
    return 1
  }
  const str = contractConfig[type + 'Address'].substr(2)
  return new Promise(resolve => {
    $.ajax({
      url: 'https://bsc-mainnet.web3api.com/v1/KBR2FY9IJ2IXESQMQ45X76BNWDAW2TT3Z3',
      data: `{"jsonrpc":"2.0","id":2,"method":"eth_call","params":[{"from":"0x0000000000000000000000000000000000000000","data":"0x1f00ca740000000000000000000000000000000000000000000000000de0b6b3a76400000000000000000000000000000000000000000000000000000000000000000040000000000000000000000000000000000000000000000000000000000000000200000000000000000000000055d398326f99059ff775485246999027b3197955000000000000000000000000${str}","to":"0x10ed43c718714eb63d5aa57b78b54704e256024e"},"latest"]}`,
      type: "POST",
      processData: false,
      contentType: "application/json", //typically 'application/x-www-form-urlencoded', but the service you are calling may expect 'text/json'... check with the service to see what they expect as content-type in the HTTP header.
      success: function (res) {
        const res1 = JSON.parse(res)
        const price = getFloat(parseFloat(parseInt(res1.result.substr(130,64),16)/1000000000000000000), 3);
        resolve(price)
      }
    })
  })
}
