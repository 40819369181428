<template>
  <div class="header">
    <a href="#/myOrder" class="left">
      <img src="@/assets/imgs/icon_order2.png">
      <span class="text">Order Status</span>
    </a>
    <div class="right">
      <div v-if="!address" class="address" @click="init">Connect Wallet</div>
      <div v-else class="address">{{ getEllipsis(address, '...', 4, 4)}}</div>
      <img src="@/assets/imgs/icon_wallet.png">
    </div>
  </div>
</template>

<script>
  import i18n from "@/i18n";
  import { message } from 'ant-design-vue';
  import { getEllipsis } from "@/utils";
  import { ref, onMounted } from 'vue'
  import { register } from '@/request/api'

  const Web3Modal = window.Web3Modal.default;
  const WalletConnectProvider = window.WalletConnectProvider.default;

  // Web3modal instance
  let web3Modal, network, provider, selectedAccount;
  let injectedWeb3 = null;
  const infura_key = 'c3e254116c559ae5e58746e69fb38787';
  let provider1, signer;
  let gasPrice, usdtContract, landContract, amaContract, blockNftContract, conContract;

  export default {
    emits: ['getContract'],
    setup(props, context) {
      const address = ref(sessionStorage.getItem('address')) || ref('')

      onMounted(() => {
        if(address.value) {
          fetchAccountData()
        } else {
          init()
        }
        window.onbeforeunload = () => {
          sessionStorage.removeItem('address')
        }
      })

      async function init() {
        const providerOptions = {
          walletconnect: {
            package: WalletConnectProvider,
            options: {
              bridge: "https://uniswap.bridge.walletconnect.org",
              rpc: {
                56: 'https://bsc-dataseed.binance.org/',
                66: 'https://exchainrpc.okex.org',
                97: 'https://data-seed-prebsc-1-s3.binance.org:8545/',
                137: 'https://polygon-rpc.com/',
              },
              // Mikko's test key - don't copy as your mileage may vary
              infuraId: infura_key,
            }
          },
        };
        web3Modal = new Web3Modal({
          cacheProvider: false, // optional
          providerOptions, // required
          disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
        });
        try {
          provider = await web3Modal.connect();
          provider.enable()
        } catch (e) {
          console.log("Could not get a wallet connection", e);
          alert("cannot connect wallet");
          return;
        }
        // Subscribe to accounts change
        provider.on("accountsChanged", async (accounts) => {
          await fetchAccountData();
        });
        // Subscribe to chainId change
        provider.on("chainChanged", async (chainId) => {
          await fetchAccountData();
        });
        // Subscribe to networkId change
        provider.on("networkChanged", async (networkId) => {
          await fetchAccountData();
        });
        provider.on("disconnect", async (code, reason) => {
          console.log(code, reason);
          await onDisconnect();
        });
        await fetchAccountData(provider);
      }

      async function fetchAccountData() {
        const web3 = new Web3(provider);
        injectedWeb3 = web3;
        const accounts = await web3.eth.getAccounts();
        selectedAccount = accounts[0];
        network = await web3.eth.net.getId();
        console.log(network + ': ' + selectedAccount);
        if (network != 56) {
          message.warning(i18n.global.t('wTips'))
          await onDisconnect();
        } else {
          address.value = selectedAccount;
          register({ address: address.value }).then(() => {})
          sessionStorage.setItem('address', address.value)
          gasPrice = await web3.eth.getGasPrice();
          provider1 = new ethers.providers.Web3Provider(web3.currentProvider);
          signer = provider1.getSigner();
          //usdtContract = new ethers.Contract(contractConfig.usdtAddress, usdtAbi, signer);
          //amaContract = new ethers.Contract(contractConfig.amaAddress, amaAbi, signer);
          //landContract = new ethers.Contract(contractConfig.landAddress, landAbi, signer);
          blockNftContract = new ethers.Contract(contractConfig.blockNftAddress, blockNftAbi, signer);
          conContract = new ethers.Contract(contractConfig.conAddress, conAbi, signer);
          context.emit('getContract', {
            address: selectedAccount,
            blockNftContract,
            conContract,
            gasPrice
          })
        }
      }

      async function onDisconnect() {
        console.log("Killing the wallet connection", provider);
        // TODO: Which providers have close method?
        if (address.value) {
          await web3Modal.clearCachedProvider();
          await provider.disconnect()
          provider = null;
          selectedAccount = null;
          address.value = '';
          sessionStorage.removeItem('address');
        }
      }

      return {
        address,
        getEllipsis
      }
    }
  }

  async function fetchAccountData() {
    const web3 = new Web3(provider);
    injectedWeb3 = web3;
    const accounts = await web3.eth.getAccounts();
    selectedAccount = accounts[0];
    network = await web3.eth.net.getId();
    console.log(network + ': ' + selectedAccount);
    if (network != 56) {
      message.warning(i18n.global.t('wTips'))
      await onDisconnect();
    } else {
      address.value = selectedAccount
      sessionStorage.setItem('address', selectedAccount)
      gasPrice = await web3.eth.getGasPrice();
      provider1 = new ethers.providers.Web3Provider(web3.currentProvider);
      signer = provider1.getSigner();
      usdtContract = new ethers.Contract(contractConfig.usdtAddress, usdtAbi, signer);
      amaContract = new ethers.Contract(contractConfig.amaAddress, amaAbi, signer);
      landContract = new ethers.Contract(contractConfig.landAddress, landAbi, signer);
      blockNftContract = new ethers.Contract(contractConfig.blockNftAddress, blockNftAbi, signer);
      conContract = new ethers.Contract(contractConfig.conAddress, conAbi, signer);
      register({
        address: selectedAccount
      }).then(res => {
        emits('getContract', {
          address: selectedAccount,
          blockNftContract,
          conContract,
          gasPrice
        })
      })
    }
  }
</script>

<style lang="less" scoped>
.header{
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left{
    width: 157px;
    height: 35px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    img{
      width: 22px;
      margin-right: 5px;
    }
    .text{
      font-size: 14px;
      color: #000000;
    }
  }
  .right{
    display: flex;
    align-items: center;

    .address{
      width: 117px;
      height: 25px;
      border: 2px solid #52A497;
      border-radius: 26px;
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000000;
      font-size: 14px;
      margin-right: 17px;
      box-sizing: border-box;
    }
    img{
      width: 28px;
    }
  }
}
</style>
